html {
  min-height: 100%;
  background-color: #000;
}

.main.background-app {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.background-modal {
  background-color: #000;
}

.background-not-fullscreen {
  background-color: red;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.version {
  background-color: var(--color-dark-gray);
  padding: 0.3rem 0.5rem;
  border-radius: 11px;
  color: #fff;
}

.content-app {
  display: flex;
  flex-direction: column;
  padding-bottom: calc(15px + env(safe-area-inset-top));
  background-color: transparent;
  padding-top: 11px;
}

.content-app.has-product {
  padding-bottom: calc(80px + env(safe-area-inset-top));
}

.content-app.content-app-scroll {
  padding-bottom: calc(83px + env(safe-area-inset-bottom));
}

.content-app.menu-fixed {
  padding-bottom: calc(110px + env(safe-area-inset-bottom));
}

.content-app.header-fixed {
  padding-top: calc(55px + env(safe-area-inset-top));
}

.header.app {
  top: 0;
  padding-top: env(safe-area-inset-top);
  z-index: 20;
}

.menu-nav.app {
  background-color: #000;
  z-index: 1000;
}

.feed-item-border {
  border-bottom: 0.22px solid #212121;
}

.fade-up {
  animation: fadeUp 0.5s ease-in-out 0s;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.test-input {
  background-color: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-primary);
  color: #fff;
  padding-bottom: 0;
}

.test-input::placeholder {
  font-weight: bold;
  color: #fff;
}

.activity-item-border {
  border-bottom: 0.22px solid #212121;
}

.treat-selector .treat-selector__control {
  background-color: transparent;
  border: none;
  min-height: 0;
  font-weight: bold;
  box-shadow: none;
  max-width: 65px;
}

.treat-selector .treat-selector__menu {
  z-index: 10;
  background-color: #141414;
  color: #fff;
  font-weight: bold;
}

.treat-selector .treat-selector__option--is-selected {
  background-color: #141414;
  color: #fff !important;
}

.treat-selector .treat-selector__option {
  background-color: #141414 !important;
}

.custom-phone-input .react-tel-input .flag-dropdown .selected-flag .iti-arrow {
  display: none;
}

.custom-phone-input .react-tel-input .flag-dropdown .selected-flag {
  position: relative;
}

.custom-phone-input .react-tel-input .flag-dropdown .selected-flag .custom-chevron {
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}

.treat-selector .treat-selector__indicator-separator {
  display: none;
}

.treat-selector .treat-selector__indicator {
  margin: 0;
}

.btn-group .checked {
  background-color: var(--color-primary);
}

.btn-group label {
  border: none;
  background: rgba(29, 155, 240, 0.5);
}

.btn-group {
  width: 100px;
  margin-top: 0.5rem;
}

.treat-selector .treat-selector__value-container {
  padding: 0;
  box-shadow: none;
}

body {
  min-height: -webkit-fill-available;
}

* {
  overscroll-behavior-y: contain;
}

.refresh-container {
  height: 0;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.refresh-container.loading {
  padding-top: calc(env(safe-area-inset-top) + 5.5rem);
}

.refresh-container .refresh-icon {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-70%, -50%);
}

.refresh-icon svg {
  width: 20px;
}

.refresh-container.loading svg {
  -webkit-animation: rotate 0.75s linear infinite;
  animation: rotate 0.75s linear infinite;
}

#buytickets:empty:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 10;
}

#buytickets:empty:after {
  content: ' ';
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: rotate 0.75s linear infinite;
  animation: rotate 0.75s linear infinite;
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  z-index: 11;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.description-title {
  line-height: 19.33px;
}

.description-subtitle {
  line-height: 19.33px;
}

.divider {
  height: 1px;
}

.header-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.new-home-title {
  margin-top: 50%;
}

.secondary-px {
  padding: 0 20%;
}

.header-title {
  position: relative;
  color: var(--color-font-primary);
}

.header.app .header-image-logo {
  width: 3.5rem;
  height: 3.5rem;
  left: -1.75rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid var(--color-medium-gray);
}

.header.app .image-border-transparent {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid transparent;
  position: relative;
  right: 0;
  z-index: 2;
  background-color: #000;
}

.header.app .has-amount {
  border: 3px solid var(--color-green);
}

.container-title {
  padding-left: 2rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  position: relative;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  line-height: 1rem;
  background-color: var(--color-medium-gray);
}

.mobile-padding {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

.header .back {
  text-align: center;
  z-index: 1;
}

.header .back-button {
  width: 55px;
  height: 55px;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

.header .header-title {
  text-align: center;
  font-size: 1.1rem;
}

.header .header-title.center {
  margin-left: -55px;
}

.header-modal .header-subtitle {
  color: var(--color-light-gray);
  letter-spacing: 1.33px;
  line-height: 17.33px;
}

.header .profile-image {
  display: block;
  background-color: var(--color-primary);
  border: 3px solid #fff;
  width: 50px;
  height: 50px;
  line-height: initial;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
}

.header .user-capital {
  line-height: 50px;
  font-size: 1.4rem;
}

.user-capital-detail {
  line-height: 100px;
  font-size: 3rem;
}

.user-capital {
  font-size: 1.5rem;
}

.header .profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header .logo-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 0.5px solid var(--color-light-gray);
  padding: 0.15rem;
}

.user-online {
  width: 11px;
  height: 11px;
  position: absolute;
  display: block;
  background-color: var(--color-green);
  border-radius: 50%;
  top: 40px;
  left: 48px;
  border: 1px solid var(--color-medium-gray);
}

.header .username {
  line-height: 1;
  margin-top: 0.15rem;
}

.img-send-invite {
  width: 100px;
}

.send-invite-subtitle {
  color: var(--color-primary);
}

.input-user-profile {
  background: none;
  border: none;
  color: var(--color-light-gray);
}

.hide-indicator-separator .css-1u9des2-indicatorSeparator {
  display: none;
}

.show-flags .PhoneInputCountry {
  display: flex;
}

.card-show {
  background-color: #2b2b2b;
  height: 100%;
  position: relative;
  border: none;
}

.card-show::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.card-img-container {
  width: 60px;
  height: 60px;
}

/* Agrega esta clase con la animación cuando showSearchScreen es true */
.search-open {
  animation: slideFromBottom 0.3s ease-in-out forwards;
}

@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.line {
  height: 0.1px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
}

.card-show .btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
}

.reload-btn {
  position: absolute;
  top: 20px;
  z-index: 1231;
  right: 30px;
}

.reservation-phone .PhoneInputInput {
  background-color: transparent;
  color: #fff;
  border: none;
  border-radius: 0;
  padding: 0.5rem 0;
  font-weight: bold;
}

.profile-phone input {
  border: none;
  margin-left: 0.2rem;
  padding: 0;
  background-color: transparent;
  color: #fff;
}

.profile-phone input {
  margin-left: 0.4rem;
  padding: 0.5rem 0;
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

.PhoneInputCountrySelect {
  width: 80%;
}

.category-img {
  width: 45px;
  height: 45px;
}

.gear {
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search {
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: 0;
}

.button-menu {
  border: 1px solid var(--color-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
}

.calendar-balance {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translate(-50%, -29%);
  background-color: #fff;
  border-radius: 5px;
  border: 0.33px solid #bbb;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  width: 98%;
  color: #fff;
  z-index: 5000;
}

.header-picture {
  color: white;
  @media (prefers-color-scheme: light) {
    color: white;
  }
  @media (prefers-color-scheme: dark) {
    color: white;
  }
}

/* Pasar al componente ui-components.common.numpad*/
.keypad .keyboard .key {
  line-height: 1.4 !important;
}

/* FONT SIZES */

.font-extra-large {
  font-size: 1.8rem;
}

.font-large {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.font-medium {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.font-normal {
  font-size: 1.1rem;
}

.font-1rem {
  font-size: 1rem;
}

.font-small {
  font-size: 0.9rem;
}

.font-smaller {
  font-size: 0.8rem;
}

.font-tiny {
  font-size: 0.85rem;
}

.font-extra-small {
  font-size: 0.75rem;
}

.fw-normal {
  font-weight: normal;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold,
.bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.semi-bold {
  font-weight: 500;
}

.light {
  font-weight: lighter;
}

.text-medium {
  font-size: 0.9375rem !important;
}

.border-none {
  border: none !important;
}

.sentences-capitalize:first-letter {
  text-transform: capitalize;
}

/* LINE HEIGHT */

.lh-title {
  line-height: 2rem;
}

.lh-subtitle {
  line-height: 0.9;
}

.pick {
  font-size: 12px;
}

.info {
  color: #808080;
  font-size: 12px;
}

.jdArhU {
  padding: 20px;
  position: absolute;
  width: 83%;
  background-color: #fff;
  top: 50%;
  border-radius: 10px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  left: 50%;
}

.input-character .field {
  border: none;
}

.field .input-single-character input {
  margin: 0 0.4rem;
  background-color: #000;
  border: 1px solid var(--color-primary);
  color: #fff;
}

.link-mp-account-form .field input {
  height: 2rem;
}

.reservation-phone .PhoneInput {
  display: flex;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  margin-left: -15px;
}

.reservation-phone .PhoneInputCountry {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 90px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 0;
}

.reservation-phone .PhoneInputCountryIcon {
  margin-left: 0.5rem;
}

.treat-selector .treat-selector__single-value {
  color: #fff !important;
  margin-right: 0;
  margin-left: 0.5rem;
}

.testing-wrap {
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-text {
  color: #525252;
}

.rdrMonth {
  width: 100%;
}

.rdrMonths {
  justify-content: center;
}

.rdrMonthPicker select {
  text-transform: capitalize;
}

.rdrMonthName {
  text-transform: capitalize;
}

.subtitle-text {
  color: var(--color-deep-gray);
  font-size: 0.85rem;
}

.activity-item-container {
  border-bottom: 1px solid #171717;
  padding: 0.55rem 0;
}

.activity-item-container:last-child {
  border-bottom: none;
}

.account-button {
  text-align: center;
  height: 2rem;
  font-size: 0.85rem;
  line-height: 2rem;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
  padding: 0 0.5rem;
  color: var(--color-deep-gray);
  cursor: pointer;
}

.account-button.selected {
  background-color: #191919;
  color: var(--color-lighter-gray);
}

/* .btn-account .btn-arrow {
  position: absolute;
  right: 2rem;
} */

.input-create-campaign {
  border: 1px solid #7e7e7e;
  color: rgb(255, 255, 255) !important;
}
.editing-text-input {
  border: 1px solid #7e7e7e;
  color: rgb(255, 255, 255) !important;
}

.no-scroll {
  overflow: hidden;
}

.month-selector .month-selector__control {
  background-color: var(--color-primary);

  border: none;
  height: 100%;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  border: none;
  outline: none;
}

.month-selector .month-selector__indicator-separator {
  display: none;
}

.month-selector .month-selector__option {
  padding: 0.25rem 0.5rem;
  color: #000;
}

.month-selector .css-tj5bde-Svg {
  margin-right: 0;
}

.month-selector .month-selector__placeholder,
.month-selector,
.modtransportal-selector .month-selector__dropdown-indicator {
  font-weight: lighter;
  color: #000;
  font-weight: 500;
}

.month-selector .time-selected .month-selector__placeholder {
  font-weight: bold;
  color: #fff;
}

.month-selector .month-selector__single-value {
  font-weight: bold;
  color: #000;
}

.month-selector .month-selector__single-value div {
  justify-content: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  background-color: #fff;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  background-color: #fff;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  background-color: #fff;
}

.input:-internal-autofill-selected {
  border: 1px solid red;
}

.censured-text {
  position: absolute;
  color: #fff;
  z-index: 999;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.censured-content {
  filter: blur(10px);
  z-index: 99;
  pointer-events: none;
}

.qr-container {
  border-radius: 20px;
}
