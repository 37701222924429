.filter_select .filter-select__control {
  border: none;
  outline: 0;
  background-color: var(--color-dark-gray);
  box-shadow: none;
  color: #000;
  padding: 0.25rem 0.5rem;
}

.filter-select .filter-select__menu {
  background-color: var(--bg-primary);
  margin-top: 0;
  min-width: 150px;
}

.filter-select__option {
  background-color: transparent;
  color: #000;
}

.filter-select__single-value {
  color: #fff;
  padding: 0;
  font-weight: bold;
}

.filter-select__value-container {
  padding: 0 3px;
  color: var(--color-primary);
}

.filter-select__placeholder {
  color: #fff;
}

.error-label {
  background-color: #f80005;
  font-size: 12px;
  margin-top: 8px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 92%;
  margin-right: 2rem;
  z-index: 999;
}

.error-label:after {
  border: 5px solid transparent;
  border-bottom-color: #f80005;
  bottom: 100%;
  content: '';
  left: 15px;
  margin-left: -5px;
  position: absolute;
}
