.camera-container {
  position: relative !important;
}

.mask-rectangle video {
  padding: 0.75rem;
  background: linear-gradient(#000, #000) top left, linear-gradient(#000, #000) top left,
    linear-gradient(#000, #000) top right, linear-gradient(#000, #000) top right,
    linear-gradient(#000, #000) bottom left, linear-gradient(#000, #000) bottom left,
    linear-gradient(#000, #000) bottom right, linear-gradient(#000, #000) bottom right;
  background-size: 1px 35px, 45px 1px;
  background-repeat: no-repeat;
}

.mask-rectangle {
  color: #000;
}

.mask-rectangle video {
  background-color: #fff;
  object-fit: cover;
}

/* .mask-face .hQxlaY{
  height: 120%;
} */

/* .mask-face video {
  object-fit: cover;
} */

.mask-face .face-image {
  height: 250px;
  object-fit: cover;
  width: 100%;
}

/* .mask-face.processing video {
  display: none;
} */

.mask-face img {
  width: 100%;
}

/* .validate .mask-face .face::after{
  top: 43%;
} */

.camera-container .face {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
}

.camera-container .face::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 100pc #000;
  width: 60%;
  height: 100%;
  border: 4px solid var(--color-primary);
  border-radius: 170px / 250px;
}

.circle-button {
  display: flex;
  justify-content: center;
  left: 0;
  bottom: 1.5rem;
}

.circle-button button {
  background-color: #d9d9d9;
}

.picture-button {
  width: 90px;
  height: 90px;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
}

.jdArhU {
  z-index: 99;
  font-size: 0.9rem;
}
