.containerImg {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #777777;
}

.allLogo {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.restaurantImg {
  border-radius: 50%;
  width: 90%;
  height: 90%;
}

.logoBorder {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #29a71a;
}
