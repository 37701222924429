.kyc-status {
  padding: calc(35% + env(safe-area-inset-top)) 1rem;
  background-image: url(../../../../images//background-login.png) !important;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.continue-button {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  font-size: 1.1rem;
  left: 0;
  bottom: 3rem;
}