.field-border-container {
  display: flex;
  position: relative;
}

.field-border-container.error {
  padding-bottom: 25px;
}

.field-border,
.field-border.mp-field {
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  padding: 0.5rem 0;
  position: relative;
  width: 100%;
}
.field-border-container.error .field-border,
.field-border-container.error .field-border.mp-field {
  border: 1px solid var(--color-red);
}

/* .field-border-container.disabled-input .field-border {
  border: 1px solid transparent;
} */

.field-border input {
  display: block;
  color: #fff;
  width: 100%;
  outline: 0;
  background-color: transparent;
  font-size: 1.1rem;
  border: none;
  position: relative;
  padding: 0.7rem;
  font-weight: 500;
}

.field-border textarea {
  display: block;
  color: #7e7e7e;
  width: 100%;
  outline: 0;
  background-color: transparent;
  font-size: 1.1rem;
  border: none;
  position: relative;
  padding: 0.7rem;
  font-weight: 500;
}

.field-border input::placeholder {
  color: var(--color-deep-gray);
  opacity: 1;
}

.field-border input::-ms-input-placeholder {
  color: var(--color-deep-gray);
}

.field-border input:-webkit-autofill,
.field-border input:-webkit-autofill:hover,
.field-border input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 10rem #000000 inset;
}

.field-border .mp-input {
  padding: 0 15px;
  height: 41px;
  background-color: unset;
  color: #fff;
}

.field-border .mp-input input {
  color: #fff;
}

.field-border .icon-wellet {
  font-size: 24px;
  left: 1.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.errorLabel {
  background-color: #f80005;
  font-size: 0.8rem;
  margin-top: 8px;
  padding: 2px 5px;
  position: absolute;
  width: 100%;

  border-radius: 10px;
  bottom: 0;
  text-align: center;
}

.errorLabelCampaign {
  background-color: #f80005;
  font-size: 0.8rem;
  margin-top: 8px;
  padding: 2px 5px;
  width: 100%;
  z-index: 999;
  border-radius: 10px;
  bottom: 0;
  text-align: center;
}

.errorLabel svg {
  margin-bottom: -2px;
}

.reveal {
  position: absolute;
  color: rgb(49, 0, 128);
  right: 40px;
  top: 34%;
  text-indent: 20px;
  transform: translate(50%, -50%);
  margin-top: 10px;
}

.field-border .input-placeholder {
  position: relative;
}

.field-border .input-placeholder input {
  position: relative;
}

.field-border .input-icon {
  padding-left: 90px;
}

.field-border .input-icon input {
  padding: 0.7rem 0;
}

.input-placeholder {
  position: relative;
}

.field-border .treat-selector {
  margin-left: 0.5rem;
}

.floatingPlaceholder,
.field-border-name,
.input-field-name {
  position: absolute;
  top: -13px;
  left: 15px;
  background-color: #000;
  padding: 0 0.3rem;
  font-weight: 500;
}

.field-border .PhoneInputInput {
  background-color: initial;
  border: none;
  border-radius: 0;
  color: #fff;
  font-weight: 500;
  margin-right: 15px;
  outline: none;
  padding: 0.7rem 0;
}

.field-border .PhoneInputCountry {
  flex: 0 0 auto;
  margin-right: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 90px;
}

.field-border .PhoneInputCountryIcon {
  margin-left: 0.25rem;
}

.field-border .PhoneInputCountrySelectArrow {
  display: none;
}

.field-border .chevron {
  height: 15px;
  left: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.field-border .chevron svg {
  vertical-align: initial;
}

.field-border-container.disabled-input .treat-selector .treat-selector__indicator,
.field-border-container.disabled-input .phone .chevron {
  display: none;
}

.text-area-styles {
  width: 100%;
  border: none;
  border-radius: 10px;
  height: 150px;
  padding: 1rem 1.7rem 1rem 1rem;
  outline: none;
  resize: none;
  position: relative;
}
