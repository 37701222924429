.carouselSlide {
  justify-content: center;
}

.carouselDots {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: var(--color-lighter-gray);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.dot.active {
  background-color: var(--color-primary);
}

.imgPrincipalOnboarding {
  width: 268.7px;
  height: 299.74px;
  margin-bottom: 13px;
  max-width: 100%;
  max-height: 100%;
}

.imgOnboarding {
  width: 289px;
  height: 318px;
  max-width: 100%;
  max-height: 100%;
}

.imgTwoStyles {
  width: 314.38px;
  height: 272.33px;
  margin-bottom: 46px;
  max-width: 100%;
  max-height: 100%;
}

.imgThreeStyles {
  width: 216.94px;
  height: 312.8px;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 6px;
}

.title {
  padding-bottom: 10px;
  font-weight: 800;
  line-height: 29.05px;
  letter-spacing: -0.5px;
  font-family: 'Inter';
  color: #d9d9d9;
  line-height: 1.2;
  font-size: 24px;
  margin-top: 20px;
}
.titleNickName {
  padding-bottom: 10px;
  font-weight: 800;
  line-height: 29.05px;
  letter-spacing: -0.5px;
  font-family: 'Inter';
  color: #d9d9d9;
  line-height: 1.2;
  font-size: 20px;
}

.textDescription {
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.5px;
  text-align: center;
  font-family: 'Inter';
  color: #7e7e7e;
  line-height: 1.2;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.fixedButton {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
}
.flexMainContainer {
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flexContent {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.flexFiles {
  flex: 3;
  overflow-y: auto;
}
