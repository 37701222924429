.imageContainer img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--color-primary);
  object-fit: cover;
}

.imageContainer {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.associateUserImage {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  margin: 0.5rem 0rem;
  height: 100px;
}

.copyButton {
  background-color: var(--color-dark-gray);
  border-radius: 15px;
}

.shareButton {
  color: #000;
  background-color: var(--color-primary);
  border-radius: 15px;
}
