body {
  font-family: 'Inter', sans-serif;
  color: #fff;
  touch-action: pan-y;
  line-height: normal;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: transparent;
  letter-spacing: -0.5px;
}

.title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: 'center';
}

.text-12 {
  font-size: 12px;
}

.text-50 {
  font-size: 50px;
}

input {
  -webkit-appearance: none;
}

button,
input {
  outline: none;
}

a {
  color: var(--color-light-blue);
}

.text-gray {
  color: var(--color-light-gray);
}

.text-description {
  color: #989898;
  font-size: 14px;
}

.text-green {
  color: var(--color-green);
}

.font-weight-500 {
  font-weight: 500;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-initial {
  line-height: initial;
}

.text-underline {
  text-decoration: underline;
}

.btn {
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 700;
  height: 55px;
  line-height: 55px;
  outline: none;
  padding-bottom: 0;
  padding-top: 0;
}

.btn-sm {
  padding: 0.5rem;
  height: unset;
  line-height: inherit;
}

.btn.loading {
  pointer-events: none;
}

.btn i {
  vertical-align: middle;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled {
  opacity: 1 !important;
}

.btn-lg {
  height: 60px;
  line-height: 60px;
}

.btn-md {
  height: 38px;
  line-height: 38px;
}

.btn-rounded {
  border-radius: 0.8rem !important;
}

.btn-unstyled,
.btn-unstyled:focus {
  border: none;
  background-color: transparent;
  outline: 0;
}

.btn-checkout {
  align-items: center;
  z-index: 99999999;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.btn-checkout .label {
  flex-grow: 0;
}

.btn-checkout .label.grow {
  flex-grow: 1;
}

.btn-checkout .quantity {
  display: flex;
  margin-left: 0.5rem;
  background-color: var(--color-red);
  border-radius: 0.25rem;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.btn-checkout .total {
  flex-grow: 1;
  text-align: right;
}

.btn-gray {
  background-color: var(--color-light-gray);
}

.input-transparent {
  border: none;
  outline: 0;
  background-color: transparent;
  padding-right: 5px;
  color: var(--color-light-gray);
}

.modal-backdrop {
  z-index: 0;
}

.modal {
  background-color: rgba(15, 15, 15, 0.9);
  z-index: 99999;
}

.modal-content {
  color: #000;
  background-color: var(--bg-primary);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  padding-top: calc(1rem + env(safe-area-inset-top));
}

.modal-content .close {
  color: #fff;
  text-shadow: none;
  opacity: 0.65;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.modal-content .close-chevron {
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 100;
  width: 30px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  color: #fff;
  transform: rotate(180deg);
  border: none;
  background-color: transparent;
}

.modal-fullscreen {
  height: 100vh;
  padding: 0 !important;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.modal-fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100vh;
  margin: 0;
  background-color: var(--color-dark-gray);
}

.modal-fullscreen .background-app {
  background: var(--bg-primary);
  color: var(--color-font-primary);
  /* background: linear-gradient(180deg, #190d21 0%, #000 70%); */
}

.modal-fullscreen .modal-content {
  height: 100vh;
  border: 0;
  border-radius: 0;
  overflow-y: auto;
}

.modal-first {
  flex: 1 1 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.modal-second {
  flex: 1 1 0;
}

.modal-button-container {
  padding-bottom: env(safe-area-inset-bottom);
}

.modal-fullscreen .font-small .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  opacity: 1;
  text-shadow: none;
  font-weight: 300;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.start-50 {
  left: 50% !important;
}

.top-50 {
  top: 50% !important;
}

.item-modal {
  flex-grow: 1;
}
.inner-fade.processing {
  position: relative;
}

.inner-fade.processing::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.close-page-container {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.close-page {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 0.5rem;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .close-page--no-padding {
    right: 0;
  }
}

.mdiv {
  height: 22px;
  width: 2px;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: 1;
  top: 50%;
  position: absolute;
  margin-top: -11px;
  left: 50%;
  margin-left: -1px;
}

.md {
  height: 22px;
  width: 2px;
  background-color: #fff;
  transform: rotate(90deg);
  z-index: 2;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.z-0 {
  z-index: 0;
}

.disabled {
  cursor: inherit;
  pointer-events: none;
  position: relative;
  z-index: 1;
  background-color: var(--color-light-gray);
}

button:disabled,
button[disabled] {
  cursor: inherit;
  pointer-events: none;
}

.border-h75::after {
  content: '';
  height: 75%;
  border-left: 1px solid var(--color-dark-gray);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.icon-img {
  width: 25px !important;
  height: 25px !important;
}

.activity-feed .item-type .icon-img {
  width: 25px !important;
  height: 25px !important;
}

.badge {
  border-radius: 3px;
  font-weight: normal;
  padding: 0.25rem 0.5rem;
}

.card {
  border-radius: 0.5rem;
}

.card-header,
.card-footer {
  padding: 1rem 0.5rem;
}

.card-bottom {
  border: 0.22px solid #212121;
  margin: 0 0.5rem;
}

.h-100-vh {
  height: 100vh;
}
.h-80 {
  height: 80%;
}
.h-10 {
  height: 10%;
}

.input-select {
  width: 100%;
  padding: 5px 10px;
  border: none;
  outline: 0;
  border-radius: 3px;
  background: #fff;
  font-size: 1.1rem;
  height: 60px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.input-select div {
  padding: 0;
  color: #000;
  outline: none;
  border: none;
}
.input-select div:hover,
.input-select:hover div {
  border-color: #fff !important;
}
.input-select div span {
  background-color: #fff;
}
/* 
child-input__control
child-input__indicators
child-input__indicator-separator
child-input__value-container
child-input__placeholder 
child-input__menu 

*/
/* Animacion Fade up/down - De abajo hacia arria*/

.fade {
  opacity: 0;
}

.fade.show {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-up-down {
  transform: translateY(100%);
  opacity: 0;
}

.fade-up-horizontal {
  transform: translateX(100%);
  opacity: 0;
}

.fade-up-down.show,
.fade-up-horizontal.show {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.1s ease-out, opacity 0.3s ease-out;
}

.fade-up-down.modal-fullscreen,
.fade-up-horizontal.modal-fullscreen {
  transition: transform 0.3s ease-out, opacity 0.15s linear;
}

/* Animacion Fade left-right - De izquierda hacia derecha*/
.slide-left-right {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-left-right.show {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.2s ease-out;
}

.slide-left-right.modal-fullscreen {
  transition: transform 0.3s ease-out, opacity 0.15s linear;
}

/* Animacion Fade left-right - De derecha  hacia izquierda*/
.slide-right-left {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-left.show {
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.2s ease-out;
}

.slide-right-left.modal-fullscreen {
  transition: transform 0.3s ease-out, opacity 0.15s linear;
}

/* Card Logo*/
.icon-container-no-affiliated {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.affiliation-image {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
}
.diamond {
  background: #000 url('https://cdn1.wellet.mx/card-diamond.png');
  background-size: cover;
  color: #fff;
}

.platinum-pro {
  background: #000 url('https://cdn1.wellet.mx/card-platino-pro.png');
  background-size: cover;
  color: #fff;
}

.platinum {
  background: #000 url('https://cdn1.wellet.mx/card-platino.png');
  background-size: cover;
  color: #000;
}

.gold {
  background: #000 url('https://cdn1.wellet.mx/card-gold.png');
  background-size: cover;
  color: #000;
}

.silver {
  background: #000 url('https://cdn1.wellet.mx/card-silver.png');
  background-size: cover;
  color: #000;
}
