
.wellet-net-toast-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, bottom 0.5s ease-in-out;
}

.wellet-net-toasts {
  display: flex;
  align-items: center;
  background-color: #28a745;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  box-sizing: border-box;
}

.toast-show {
  opacity: 1;
}

.bottom-center.toast-show {
  bottom: 45px;
}

.bottom-center {
  bottom: 0;
}


.wellet-net-toasts-body {
  display: flex;
  align-items: center;
  gap: 10px; 
  width: 100%;
  justify-content: center; 
}

.wellet-toasts-icon {
  flex-shrink: 0; 
}

.wellet-net-toasts.toast-error {
  background-color: var(--color-red);
}