.containerTreatment {
  z-index: 5;
  left: 23%;
  top: 6%;
}

.customerNameContainer {
  background-color: #141414;
  color: #fff;
  border-radius: 7px;
  border: none;
}

.containerPhoneCountry {
  color: grey;
  z-index: 1;
  left: 10px;
  font-size: 0.75rem;
  top: 5%;
  position: absolute;
  pointer-events: none;
}

.containerPhoneNumber {
  position: absolute;
  pointer-events: none;
  color: grey;
  z-index: 1;
  left: 26%;
  font-size: 0.75rem;
  top: 5%;
}

.chevronIcon {
  position: absolute;
  top: 30%;
  left: 13%;
}

.phoneError {
  background-color: #f80005;
  font-size: 12px;
  margin-top: 8px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 93%;
  margin-right: 2rem;
}

.phoneError:after {
  border: 5px solid transparent;
  border-bottom-color: #f80005;
  bottom: 100%;
  content: '';
  left: 15px;
  margin-left: -5px;
  position: absolute;
}

.phoneInputContainer {
  position: relative;
  padding-left: 0.3rem;
}

.custom-label {
  background-color: #f80005;
  margin-top: 8px;
  font-size: 12px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 100%;
}

.btn:disabled {
  background-color: var(--color-deep-gray) !important;
  color: #000 !important;
}

.roleInput {
  background-color: #000;
  box-shadow: none;
  border: 1px solid var(--color-primary);
  height: 61.38px;
  border-radius: 10px;
  padding-left: 1rem;
  color: #fff;
  font-weight: bold;
}

.errorLabel {
  background-color: #f80005;
  font-size: 12px;
  margin-top: 8px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 92%;
  margin-right: 2rem;
  color: #fff;
}

.errorLabel:after {
  border: 5px solid transparent;
  border-bottom-color: #f80005;
  bottom: 100%;
  content: '';
  left: 15px;
  margin-left: -5px;
  position: absolute;
}

.terms :is(h1, h2, h3, h4, h5, h6) {
  font-size: 1.2rem;
}

.toggleButtons button {
  width: 55px;
  height: 40px;
  line-height: 40px;
  border: none;
}

.toggleButtons button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggleButtons button:last-child {
  border-left: 1px solid var(--color-medium-gray);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
